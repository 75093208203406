<script setup>
import { useAnalyticsStore } from "~/store/useAnalyticsStore.js";
import abbreviate from "number-abbreviate";
import { humanReadableRange } from "~/utils/dateTime";
import moment from "moment";

const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  count: {
    type: String,
    required: true,
    default: "-",
  },
  bgImage: {
    type: String,
    required: false,
  },
  iconBg: {
    type: String,
    required: true,
  },
  iconColor: {
    type: String,
    required: true,
  },
  showTodayCount: {
    type: Boolean,
    required: false,
  },
  todayCount: {
    type: Number,
    required: false,
  },
  startDateTime: {
    type: Object,
    required: true,
  },
  endDateTime: {
    type: Object,
    required: true,
  },
});

const duration = computed(() =>
  humanReadableRange(props.startDateTime, props.endDateTime)
);
</script>

<template>
  <div
    class="border bg-white dark:bg-[#181818] p-4 rounded-xl flex items-center justify-center w-full mr-0 h-40 max-h-80 md:max-h-80 lg:max-h-80 overflow-hidden dark:border-[#181818]"
  >
    <div class="w-full flex items-center">
      <div class="align-baseline h-full w-full">
        <div class="flex justify-between w-full mb-3">
          <div class="">
            <span
              class="block text-gray-500 dark:text-slate-50 font-medium mb-3 md:text-sm lg:text-base text-left"
              >{{ header }}</span
            >
            <div class="text-900 dark:text-slate-50 font-semibold text-xl text-left">
              {{ count }}
              <span v-if="showTodayCount" class="text-xs text-green-500">
                <span class="font-bold text-sm">{{ todayCount }}+ </span>Today
              </span>
            </div>
          </div>
          <div
            class="flex items-center justify-center rounded-lg h-10 w-10 md:h-7 md:w-7 lg:w-10 lg:h-10"
            style=""
            :class="iconBg"
          >
            <i class="pi pi-link text-xl" :class="iconColor"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">
          {{ duration }}
        </span>
      </div>
    </div>
  </div>
</template>

<style></style>
